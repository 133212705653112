/* eslint-disable no-unused-expressions */
/* eslint-disable array-callback-return */
import React, { FC, useState, useEffect } from 'react';
import {
  SocialShare,
  PageLayout,
  PageHeading,
  ThumbnailText,
  RenderVideoAndImage,
  FAQ,
  EnquireFormModal,
  Brands,
  NDISCondition,
} from 'components';
import { graphql, Link } from 'gatsby';
import { ServiceDetailPageType } from 'types';
import ReactMarkdown from 'react-markdown';
import { SlideUpPageContent } from 'components/animations';
import PDFIcon from 'assets/svg/pdf.svg';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useLocation } from '@reach/router';

const ServiceDetailPage: FC<ServiceDetailPageType> = ({ data }: any) => {
  const { pathname } = useLocation();

  const {
    ServiceDetailPage: {
      title,
      tagline,
      description,
      secondDescription,
      image,
      secondImage,
      service,
      enquireButtonText,
      contactFormModalTitle,
      ServiceVideoImage,
      seo,
      ctaLink,
      faqs,
      enquirySection,
      fileDownload,
      brandsWorkedWith,
      banner,
      strapiParent,
    },
    strapiServicesPage,
    allStrapiNdsiCategories,
    allStrapiNdsiConditions,
  } = data;
  const VideoGallery: any = [];
  useEffect(() => {
    if (ServiceVideoImage && ServiceVideoImage.video) {
      VideoGallery.push(ServiceVideoImage.video?.localFile.publicURL);
    }
  }, [VideoGallery]);

  const [isEnquireModalOpen, setisEnquireModalOpen] = useState(false);
  const [enquiryModalTitle, setEnquiryModalTitle] = useState(
    contactFormModalTitle
  );

  const [enquiryModalText, setEnquiryModalText] = useState('');

  const handleEnquireModalOpen = () => {
    setisEnquireModalOpen(true);
  };

  const handleEnquirySectionButton = (button: any) => {
    return () => {
      setEnquiryModalTitle(button.popupTitle);
      setEnquiryModalText(button.popupText);
      setisEnquireModalOpen(true);
    };
  };

  const showBrand = !brandsWorkedWith ? true : brandsWorkedWith?.showBrand;
  const Image: any =
    image && image.localFile
      ? getImage(image.localFile.childImageSharp?.gatsbyImageData)
      : null;
  const secondDescImage: any =
    secondImage && secondImage.localFile
      ? getImage(secondImage.localFile.childImageSharp?.gatsbyImageData)
      : null;

  return (
    <PageLayout
      title={title}
      metaTitle={seo?.metaTitle ?? title}
      description={seo?.metaDescription}
      image={seo?.shareImage?.localFile?.publicURL}
      showBrand={showBrand}
      bannerTitle={banner?.title || strapiParent?.banner?.title}
      bannerTagline={banner?.tagline || strapiParent?.banner?.tagline}
      bannerImage={banner?.image || strapiParent?.banner?.image}
      bannerButton={banner?.ctaButton || strapiParent?.banner?.ctaButton}
      bannerPlaceholder={
        banner?.postcodePlaceholder || strapiParent?.banner?.postcodePlaceholder
      }
      showNdisFields={pathname === `/${strapiServicesPage.ndisPageSlug}/`}
      faqs={faqs?.faqs}
    >
      <div className="container">
        <PageHeading title={title} subtitle={tagline} level={1}>
          {fileDownload &&
            fileDownload?.file?.localFile?.publicURL &&
            fileDownload.buttonText && (
              <a
                href={fileDownload?.file?.localFile?.publicURL}
                target="_blank"
                rel="noreferrer"
                className="pdfDownload"
              >
                <PDFIcon />
                {fileDownload.buttonText}
              </a>
            )}
        </PageHeading>
        <SlideUpPageContent>
          <div className="service-page-description">
            <div className="service-page-description-content">
              <ReactMarkdown className="md-html">{description}</ReactMarkdown>
            </div>
            <div className="service-page-image">
              <GatsbyImage
                image={Image}
                alt={(image?.alternativeText && title) || ''}
              />
              {Image && (
                <SocialShare
                  title={title}
                  image={Image?.image?.localFile?.publicURL}
                />
              )}
            </div>
          </div>
          <div className="service-page-description">
            <div className="service-page-description-content">
              <ReactMarkdown className="md-html">
                {secondDescription}
              </ReactMarkdown>
            </div>
            <div className="service-page-image">
              <GatsbyImage
                image={secondDescImage}
                alt={(secondDescImage?.alternativeText && title) || ''}
              />
            </div>
          </div>
        </SlideUpPageContent>
        {service && service.length > 1 && (
          <div className="section-wrapper">
            <ThumbnailText services={service} />
          </div>
        )}

        {ctaLink && (
          <div className="service-page-cta-link-container page-heading-content">
            <Link className="service-page-cta-link" to={ctaLink.link}>
              <span>{ctaLink.title}</span>
            </Link>
          </div>
        )}
        {enquireButtonText && (
          <div className="service-page-cta-link-container">
            <button
              className="service-page-cta-link"
              type="button"
              onClick={handleEnquireModalOpen}
            >
              <span>{enquireButtonText}</span>
            </button>
          </div>
        )}
        {enquirySection?.enquiryText && (
          <div className="service-page-cta-link-container page-heading-content enquiry-section">
            {enquirySection?.enquiryText && (
              <p className="enquiry-section_text">
                {enquirySection?.enquiryText}
              </p>
            )}
            {enquirySection?.buttons && (
              <div className="enquiry-section_buttons">
                {enquirySection?.buttons.map((button: any) => {
                  return (
                    <button
                      className="service-page-cta-link"
                      key={button.buttonText}
                      type="button"
                      onClick={handleEnquirySectionButton(button)}
                    >
                      <span>{button.buttonText}</span>
                    </button>
                  );
                })}
              </div>
            )}
          </div>
        )}

        {pathname === `/${strapiServicesPage.ndisPageSlug}/` && (
          <NDISCondition
            data={strapiServicesPage.NDISCondition}
            list={allStrapiNdsiConditions}
            categories={allStrapiNdsiCategories}
            selectedCategory={allStrapiNdsiCategories.edges[0].node.title}
            parentSlug={strapiServicesPage.ndisPageSlug}
          />
        )}
        <Brands brandsWorked={brandsWorkedWith} />
        <EnquireFormModal
          isEnquireModalOpen={isEnquireModalOpen}
          setisEnquireModalOpen={setisEnquireModalOpen}
          contactFormModalTitle={enquiryModalTitle}
          modalText={enquiryModalText}
          optionalMessage
          dealTitle={title}
          showNdisFields
        />
        <div className="servicevideo">
          {ServiceVideoImage && (
            <RenderVideoAndImage
              data={ServiceVideoImage}
              altText={title}
              hasPlayButton
              buttontype="icon"
              buttonsize="medium"
              showControls
              videogallery={VideoGallery}
              showModal
              showModalforMobile
            />
          )}
        </div>
        {faqs && faqs ? (
          <section className="thumbnail-page-faq-section">
            <PageHeading title={faqs?.title} subtitle={faqs?.tagline} />
            <FAQ Faqs={faqs?.faqs} />
          </section>
        ) : null}
      </div>
    </PageLayout>
  );
};

export default ServiceDetailPage;

export const query = graphql`
  query ($id: String!) {
    ServiceDetailPage: strapiServicePage(id: { eq: $id }) {
      title
      slug
      tagline
      description: pageDescription
      secondDescription
      image {
        localFile {
          publicURL
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      secondImage {
        localFile {
          publicURL
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      enquireButtonText
      contactFormModalTitle
      banner {
        ctaButton
        id
        postcodePlaceholder
        tagline
        title
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      faqs {
        faqs {
          answer
          id
          title
        }
        tagline
        title
      }
      enquirySection {
        enquiryText
        buttons {
          popupText
          buttonText
          popupTitle
        }
      }
      fileDownload {
        buttonText
        file {
          name
          alternativeText
          localFile {
            publicURL
          }
        }
      }
      brandsWorkedWith {
        title
        showBrand
        showTitle
        brandImage {
          image {
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
      seo {
        metaDescription
        metaTitle
        shareImage {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(width: 400, height: 400)
            }
          }
        }
      }
      strapiParent {
        slug
        banner {
          ctaButton
          id
          postcodePlaceholder
          tagline
          title
          image {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
      ServiceVideoImage {
        image {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
        }
        video {
          localFile {
            publicURL
          }
        }
      }
      ctaLink {
        link
        title
      }
      service {
        id
        title
        slug
        content
        image {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
    strapiServicesPage {
      ndisPageSlug
      NDISCondition {
        content
        title
      }
    }
    allStrapiNdsiCategories {
      edges {
        node {
          id
          title
        }
      }
    }
    allStrapiNdsiConditions {
      edges {
        node {
          id
          name
          slug
          ndis_category {
            id
            title
          }
        }
      }
    }
  }
`;
